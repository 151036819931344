html {
    background-color: #161b4e;
}

body {
    margin: 0;
    font-family: "Poppins", "Century Gothic", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
